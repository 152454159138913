<template>
  <fragment>
    <b-button v-show="showScrollToTop" variant="congress" @click="scrollToSearchBar" class="btn-floating scroller"
      ><span class="material-icons-round"> keyboard_control_key </span></b-button
    >
    <b-jumbotron data-v-step-search="0" v-if="searchDocType != 'MyProgramme'">
      <template #header v-if="ressourcesMovedTo365">
        <span>You missed {{ eventBaseTitle }}?</span>
        <!--<span v-else>Programme</span>-->
      </template>
      <template #lead v-if="ressourcesMovedTo365">
        <span
          >Check out the Replays. Browse the programme and see what is available on
          <a class="text-decoration-underline" href="https://esc365.escardio.org">ESC 365</a>.</span
        >
        <!-- <span v-else>Search through all the scientific sessions and bookmark your favourites.</span> -->
      </template>
      <b-row class="search font-regular mx-0 mb-3" id="search" ref="searchBar">
        <b-col xl="3" cols="12" class="pl-xl-0 pr-xl-1 mb-2 mb-md-0">
          <b-input-group>
            <b-form-input maxlength="100" type="search" :placeholder="searchPlaceholder" @keydown.native="onTextInputKeydown" class="searchInput" v-model="query" />
          </b-input-group>
        </b-col>
        <b-col cols="12" xl v-for="facet in getDocTypeFacets(searchDocType, true)" :key="facet[0].name" class="mb-2 mb-md-0 px-xl-1">
          <b-input-group-append :set="(facetConf = getFacetConfiguration(facet[0].name))" :sm="facetConf.sm" lazy>
            <treeselect
              :ref="`${facet[0].name}TreeSelect`"
              v-model="selectedDocTypesFacetsValues[facet[0].name]"
              :options="selectedDocTypesFacetsOptions[facet[0].name]"
              :multiple="facetConf.multiple"
              :placeholder="facetConf.placeholder"
              :limit="facetConf.limit"
              :limitText="facetConf.limitText"
              :appendToBody="true"
              @input="onTreeSelectInput"
            />
          </b-input-group-append>
        </b-col>
        <b-col class="d-flex pr-xl-0 pl-xl-1" cols="12" xl="1">
          <b-input-group-append class="w-100">
            <b-button :disabled="isComponentOrChildLoading" variant="congress" @click="onSearchClick" class="badge-pill d-xl-block with-icon w-100">
              <i v-if="!isComponentOrChildLoading" class="las la-2x la-t-plus-2 la-search"></i>
              <i v-if="isComponentOrChildLoading" class="las las la-2x la-t-plus-2 la-spin la-spinner"></i>
              Search
            </b-button>
          </b-input-group-append>
        </b-col>
      </b-row>
    </b-jumbotron>
    <div class="filter__list mb-2">
      <div v-for="facet in getDocTypeFacets(searchDocType, true)" :key="facet[0].name" class="d-inline">
        <b-badge
          pill
          v-for="filter in selectedDocTypesFacetsValues[facet[0].name]"
          :key="filter"
          class="mr-3 badge__selected font-regular btn btn-sm px-3 py-2 mb-2"
        >
          {{ filter }}
          <i class="las la-t-plus-1 la-times ml-1 clear-icon" @click="onClearFilter(facet[0].name, filter)"></i>
        </b-badge>
      </div>
      <!-- -->
      <span
        class="mb-3"
        align-h="center"
        v-if="
          searchDocType != 'MyProgramme' &&
          (selectedDocTypesFacetsValues['types'].length ||
            selectedDocTypesFacetsValues['tracks'].length ||
            selectedDocTypesFacetsValues['topics'].length ||
            selectedDocTypesFacetsValues['country'].length ||
            selectedDocTypesFacetsValues['rooms'].length)
        "
      >
        <span v-if="Object.keys(getDocTypeFacets(searchDocType, true)).length > 0" @click="onClearClick">
          <b-button size="sm" pill variant="clear" class="font-regular px-3 py-2"> Clear filters <i class="las la-t-plus-1 la-times ml-1"></i> </b-button>
        </span>
      </span>

      <!-- -->
    </div>

    <b-overlay id="tabsContainer" :show="isComponentOrChildLoading" no-center rounded="sm">
      <!-- NoResult (Text) -->
      <div v-if="!isComponentOrChildLoading && !hasResults && hasValidDoctypesCount" class="pb-3">
        <h5 v-if="query">
          No matches were found for <b>{{ query }}</b
          >.
        </h5>
        <h5 v-else>
          No matches were found for <b>{{ searchDocType }}</b
          >.
        </h5>
      </div>

      <b-tabs :key="selectedDocTypeIndex" v-if="docTypes && docTypes.length > 0" v-model="selectedDocTypeIndex" content-class="" fill @activate-tab="onActivateTab">
        <b-tab v-for="(docType, index) in docTypes" :key="docType.value" @click="onTabClick(docType, $event)" lazy>
          <template #title v-if="docTypes.length > 1">
            <b-card-title
              :class="{ tab__title_xl: index === 0, disabled: docType.count === 0, tab__title__active: selectedDocTypeIndex === index }"
              data-v-step-search="1"
            >
              <span class="tab__title">
                {{ getDocTypeHeader(docType.value) }} <span class="tab__title__count">({{ docType.count }})</span>
              </span>
            </b-card-title>
          </template>

          <!-- Results -->
          <SearchResultsBlock
            :componentKey="componentKey"
            ref="resultsBlock"
            :resultType="selectedDocType"
            :results="selectedDocTypeResultsElastic"
            :query="query"
            :serverTime="timeFromServer"
            @onShowChange="onOnShowChange"
            @select-day="setDay"
            :searchDocType="searchDocType"
            :selectedDocTypesFacetsValues="selectedDocTypesFacetsValues"
            :resultsFacets="resultsFacets"
            :eventDays="eventDates"
            :showList="showList"
            :showCalendar="showCalendar"
            :showCards="showCards"
            :daySelected="selectedDate"
            @is-loading="OnChildLoading"
            :docType="docType.value"
            :channels="channelsOptions"
          />

          <!-- NoResults (Facets) -->
          <div v-if="!isComponentOrChildLoading && (!selectedDocTypeResultsElastic || selectedDocTypeResultsElastic.length == 0)" class="pb-3">
            <h5>No matches were found. Please adjust your search criteria.</h5>
          </div>

          <!-- Pagination -->
          <b-pagination
            v-if="currentPage > 0 && selectedDocTypeResultsElastic && selectedDocTypeResultsElastic.length > 0 && showCards"
            @change="onPageChange"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            pills
          ></b-pagination>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </fragment>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { DateTime } from 'luxon';
  import Treeselect from '@riophae/vue-treeselect';
  import '@riophae/vue-treeselect/dist/vue-treeselect.css';
  //import { AppSearchService } from 'src/services/app-search';
  import { MediaApiService } from 'src/services/media-api';
  import SearchResultsBlock from 'src/components/Blocks/SearchResultsBlock';
  import { DateHelper } from 'src/utils/date-helper';
  import { constants } from 'src/constants';
  import { UPDATE_SCROLL_POSITION } from 'src/store/actions';
  import { store } from 'src/store';

  export default {
    name: 'Search',
    components: {
      Treeselect,
      SearchResultsBlock,
    },
    props: {
      searchDocType: String,
    },
    watch: {
      isPoolingEnabled(enabled) {
        if (enabled) {
          // Fetching server time.
          this.fetchtime();
        }
      },
      isComponentOrChildLoading(value) {
        if (!value) {
          if (this.scrollPosition && this.scrollPosition.y) {           
            window.scrollTo({
              top: this.scrollPosition.y,
              offset: this.scrollPosition.offset??0,
            });
            store.dispatch(UPDATE_SCROLL_POSITION, null);
          }
          else  if (this.scrollPosition && this.scrollPosition.selector) {
            let element = document.querySelector(this.scrollPosition.selector);
            if (element) {
              element.scrollIntoViewExtended({ behavior: 'smooth', block: 'start', baseOffset: 20, navOffset: true, daysBarOffset: false });
            }
            store.dispatch(UPDATE_SCROLL_POSITION, null);
          }
          else{
            this.$refs.resultsBlock[0].scrollToClosestTime();
          }
        }
        this.$emit('is-loading', value);
      },
      //topicListes() {
      //  this.updateFacets();
      //},
      //selectedDocType() {
      //  this.selectedDocTypeDefault = this.selectedDocType;
      // if (this.selectedDocType === 'Speaker') {
      //     this.selectedDocTypeResultsElastic = this.selectedDocTypeResultsContributors;
      //     this.forceRerender();
      // }
      //},
      //selectedSpeakersListFacets() {
      //  //this.refreshSessionSpeakers(1)
      //  this.addDataToSessionList(this.sessionListes);
      //},
      //query() {
      //await this.refreshSessionDetails({ currentPage: 1 });
      //   this.refreshSessionSpeakers(1);
      //},
    },
    data() {
      return {
        isLoading: true,
        isChildLoading: true,
        serverTime: null,
        timeFromServer: null,
        perPage: 20,
        componentKey: 0,
        pageFromUrl: 1,
        reloadKey: 0,
        totalSpeakersCount: 0,
        query: '',
        docTypes: [],
        selectedDocType: null,
        selectedDocTypeIndex: 0,
        resultToSend: [],
        selectedDocTypeResults: [],
        selectedDocTypeResultsElastic: [],
        selectedDocTypeResultsContributors: [],
        selectedSpeakersList: [],
        selectedSessionIndustryList: [],
        facetSessionIndustryList: {},
        selectedSpeakersListFacets: [],
        topicListes: [],
        facetContributors: [],
        sessionListes: [],
        facetsTreeSelect: {},
        selectedDocTypeDefault: null,
        selectedDocTypesFacetsOptions: {
          days: [],
          types: [],
          tracks: [],
          topics: [],
          country: [],
          specialities: [],
          rooms: [],
        },
        selectedDocTypesFacetsValues: {
          days: [],
          types: [],
          tracks: [],
          topics: [],
          country: [],
          specialities: [],
          rooms: [],
        },
        currentPage: 1,
        totalRows: 0,
        fetchTimeJob: null,
        textValue: this.query,
        showCards: true,
        daySelected: null,
        endPointToUse: null,
        showList: false,
        showCalendar: false,
        resultsFacets: null,
        channelsOptions: [],
        showScrollToTop: false,
        sort: 'MostRelevant',
      };
    },
    computed: {
      ...mapGetters(['isPoolingEnabled', 'scrollPosition']),
      ...mapState({
        eventId: (state) => state.app.eventId,
        eventStartDate: (state) => state.app.eventStartDate,
        eventEndDate: (state) => state.app.eventEndDate,
        industryStandsSearch: (state) => state.app.industryStandsSearch,
        indexAbstracts: (state) => state.app.indexAbstracts,
        indexIndustries: (state) => state.app.indexIndustries,
        indexLiveSessions: (state) => state.app.indexLiveSessions,
        indexOnDemandSessions: (state) => state.app.indexOnDemandSessions,
        indexSpeakers: (state) => state.app.indexSpeakers,
        escEventId: (state) => state.app.escEventId,
        eventBaseTitle: (state) => state.app.eventBaseTitle,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
      }),
      poolingTimeInterval() {
        return parseInt(process.env.VUE_APP_POOLING_TIMELINE_DIGEST_INTERVAL);
      },
      startDate() {
        return DateHelper.onSiteDateTime(this.eventStartDate);
      },
      endDate() {
        return DateHelper.onSiteDateTime(this.eventEndDate);
      },
      eventDates() {
        return DateHelper.getDates(this.startDate, this.endDate);
      },
      hasResults() {
        return this.docTypes && this.docTypes.some((x) => x.count > 0);
      },
      hasChannels() {
        return this.channelsOptions && this.channelsOptions.length > 0;
      },
      searchPlaceholder() {
        return this.searchDocType == 'DigitalSession' ? 'Search in the programme' : 'Search..';
      },
      hasValidDoctypesCount() {
        return this.docTypes.some((docType) => docType.count > 0);
      },
      selectedDate() {
        return new Date(this.daySelected);
      },
      defaultDay() {
        if (this.searchDocType != 'Speaker' && this.searchDocType != 'Abstract') {
          const serverTime = this.timeFromServer ? DateHelper.onSiteDateTime(this.timeFromServer) : null;
          if (serverTime >= DateHelper.onSiteDateTime(this.eventStartDate) && serverTime <= DateHelper.onSiteDateTime(this.eventEndDate)) {
            const year = serverTime.getFullYear();
            const month = String(serverTime.getMonth() + 1).padStart(2, '0');
            const day = String(serverTime.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
          } else {
            const year = this.eventDates[0].getFullYear();
            const month = String(this.eventDates[0].getMonth() + 1).padStart(2, '0');
            const day = String(this.eventDates[0].getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
          }
        } else {
          return null;
        }
      },
      isComponentOrChildLoading() {
        return this.isLoading || this.isChildLoading;
      },
    },
    created() {
      window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    async mounted() {
      // await await this.refreshSessionDetails({ currentPage: 1 });
      // await this.refreshSessionSpeakers(1);
      await this.fetchtime();
      this.getValueFromUrl();
      await this.getChannelsOptions();
      await this.refreshSessionDetails({ currentPage: this.currentPage });

      // Polling server time
      this.fetchTimeJob = setInterval(() => {
        if (this.isPoolingEnabled) {
          // Fetching server time to display...
          this.fetchtime();
        }
      }, this.poolingTimeInterval);
    },
    beforeDestroy() {
      clearInterval(this.fetchTimeJob);
    },
    methods: {
      async fetchtime() {
        const { data } = await MediaApiService.getTime();
        this.timeFromServer = data;
        //console.log('timeFromServer', this.timeFromServer);
      },
      //refresh component after add data peakers
      forceRerender() {
        this.componentKey += 1;
      },
      async handleSearch() {
        //console.log('refreshSessionDetails handleSearch');
        await this.refreshSessionDetails({ currentPage: 1 });
      },
      async onTabClick(docType, event) {
        if (docType.count === 0) {
          event.preventDefault(); // Empêche l'action par défaut
          return;
        }

        this.isLoading = true;
        this.selectedDocTypeResults = [];

        // We must use some magic at this stage...
        await new Promise((resolve) => setTimeout(resolve, 250));

        /*
        if (this.selectedDocTypesFacetsValues['types'].find((x) => x == 'Global Community')) {
          this.docTypes[this.selectedDocTypeIndex].value = 'DigitalSessionDailyHighLight';
        }
        */

        this.selectedDocType = this.docTypes[this.selectedDocTypeIndex].value;
        //console.log('refreshSessionDetails onTabClick');
        await this.refreshSessionDetails({ currentPage: 1 });
        //await this.updateFacets();
        //await this.search({ currentPage: 1 });
      },
      onClearClick() {
        // this.getDaysTreeSelect()?.clear();
        // this.getTypesAndTracksTreeSelect()?.clear();
        // this.getTopicsTreeSelect()?.clear();
        // this.getCountriesTreeSelect()?.clear();
        // this.getSpecialitiesTreeSelect()?.clear();

        this.selectedDocTypesFacetsValues['days'] = [];
        this.selectedDocTypesFacetsValues['types'] = [];
        this.selectedDocTypesFacetsValues['tracks'] = [];
        this.selectedDocTypesFacetsValues['topics'] = [];
        this.selectedDocTypesFacetsValues['country'] = [];
        this.selectedDocTypesFacetsValues['specialities'] = [];
        this.selectedDocTypesFacetsValues['rooms'] = [];
        this.collapseAllNodes();
      },
      onClearFilter(facetName, filter) {
        if (this.selectedDocTypesFacetsValues[facetName]) {
          const index = this.selectedDocTypesFacetsValues[facetName].indexOf(filter);
          if (index !== -1) {
            this.selectedDocTypesFacetsValues[facetName].splice(index, 1);
          }
        }
      },
      collapseAllNodes() {
        const treeSelectTopics = this.$refs.treeSelectTopics;
        if (treeSelectTopics && this.selectedDocTypesFacetsValues['topics']) {
          treeSelectTopics.traverseAllNodesByIndex((node) => {
            if (node.isBranch) {
              node.isExpanded = false;
            }
          });
        }
      },
      getDaysTreeSelect() {
        let daysTreeSelect = null;
        let daysTreeSelectRef = this.$refs.daysTreeSelect;
        if (daysTreeSelectRef && daysTreeSelectRef.length > 0) daysTreeSelect = daysTreeSelectRef[0];
        return daysTreeSelect;
      },
      getTypesTreeSelect() {
        let typesTreeSelect = null;
        let typesTreeSelectRef = this.$refs.typesTreeSelect;
        if (typesTreeSelectRef && typesTreeSelectRef.length > 0) typesTreeSelect = typesTreeSelectRef[0];
        return typesTreeSelect;
      },
      getTracksTreeSelect() {
        let tracksTreeSelect = null;
        let tracksTreeSelectRef = this.$refs.tracksTreeSelect;
        if (tracksTreeSelectRef && tracksTreeSelectRef.length > 0) tracksTreeSelect = tracksTreeSelectRef[0];
        return tracksTreeSelect;
      },
      getTopicsTreeSelect() {
        let topicsTreeSelect = null;
        let topicsTreeSelectRef = this.$refs.topicsTreeSelect;
        if (topicsTreeSelectRef && topicsTreeSelectRef.length > 0) topicsTreeSelect = topicsTreeSelectRef[0];
        return topicsTreeSelect;
      },
      getCountriesTreeSelect() {
        let countriesTreeSelect = null;
        let countriesTreeSelectRef = this.$refs.countryTreeSelect;
        if (countriesTreeSelectRef && countriesTreeSelectRef.length > 0) countriesTreeSelect = countriesTreeSelectRef[0];
        return countriesTreeSelect;
      },
      getSpecialitiesTreeSelect() {
        let specialitiesTreeSelect = null;
        let specialitiesTreeSelectRef = this.$refs.specialitiesTreeSelect;
        if (specialitiesTreeSelectRef && specialitiesTreeSelectRef.length > 0) specialitiesTreeSelect = specialitiesTreeSelectRef[0];
        return specialitiesTreeSelect;
      },
      getRoomsTreeSelect() {
        let roomsTreeSelect = null;
        let roomsTreeSelectRef = this.$refs.roomsTreeSelect;
        if (roomsTreeSelectRef && roomsTreeSelectRef.length > 0) roomsTreeSelect = roomsTreeSelectRef[0];
        return roomsTreeSelect;
      },
      async onPageChange(page) {
        //console.log('refreshSessionDetails onPageChange');
        await this.refreshSessionDetails({ currentPage: page }, true);
      },
      async onTreeSelectInput() {
        //console.log('refreshSessionDetails onTreeSelectInput');
        await this.refreshSessionDetails({ currentPage: 1 });
        //await this.search({ currentPage: 1 });
        /*
        if (this.selectedDocTypesFacetsValues['types'].find((x) => x == 'Global Community')) {
          this.onTabClick('Global Community');
        }
        */
      },

      async onSearchClick() {
        let oldQueryString = this.$route.query.text ? this.$route.query.text : '';
        if (oldQueryString !== this.query || this.query === '') {
          this.daySelected = this.defaultDay;
          this.scrollToSearchBar();
          await this.handleSearch();
          //this.updateFacets();
        }
      },
      hasFilters() {
        let oldQueryString = this.$route.query.text;
        //selectedDocTypesFacetsValues
        return (
          this.query != oldQueryString ||
          (this.selectedDocTypesFacetsValues['days'] && this.selectedDocTypesFacetsValues['days'].length > 0) ||
          (this.selectedDocTypesFacetsValues['types'] && this.selectedDocTypesFacetsValues['types'].length > 0) ||
          (this.selectedDocTypesFacetsValues['tracks'] && this.selectedDocTypesFacetsValues['tracks'].length > 0) ||
          (this.selectedDocTypesFacetsValues['topics'] && this.selectedDocTypesFacetsValues['topics'].length > 0) ||
          (this.selectedDocTypesFacetsValues['country'] && this.selectedDocTypesFacetsValues['country'].length > 0) ||
          //(this.selectedDocTypesFacetsValues['days'] && this.selectedDocTypesFacetsValues['days'].length > 0) ||
          (this.selectedDocTypesFacetsValues['specialities'] && this.selectedDocTypesFacetsValues['specialities'].length > 0) ||
          (this.selectedDocTypesFacetsValues['rooms'] && this.selectedDocTypesFacetsValues['rooms'].length > 0)
        );
      },
      updateQueryString() {
        let query = null;
        let vueMode = '';
        if (this.showList) vueMode = 'list';
        else if (this.showCalendar) vueMode = 'calendar';
        else vueMode = 'cards';
        query = {
          text: this.query,
          docType: this.selectedDocType,
          days: this.daySelected,
          session_type: this.selectedDocTypesFacetsValues['types'],
          tracks: this.selectedDocTypesFacetsValues['tracks'],
          topics: this.selectedDocTypesFacetsValues['topics'],
          country: this.selectedDocTypesFacetsValues['country'],
          specialities: this.selectedDocTypesFacetsValues['specialities'],
          rooms: this.selectedDocTypesFacetsValues['rooms'],
          page: this.currentPage,
          vue: vueMode,
          //pageSize: this.perPage,
        };
        this.$router.push({ query });
        /* DataLayer */
        this.$gtm.push({ event: 'onSearchSubmitted', eventId: this.eventId, ...query });
      },
      getValueFromUrl() {
        const fulltextqueryparam = this.$route.query.text;
        if (fulltextqueryparam) {
          this.query = fulltextqueryparam;
        } else {
          this.query = '';
        }

        const pageparam = this.$route.query.page;
        if (pageparam) this.currentPage = parseInt(pageparam);

        /*
        const pageSizeParam = this.$route.query.pageSize;
        if (pageSizeParam) this.perPage = parseInt(pageSizeParam);
        //else this.perPage = 20;
        */

        const filterdocTypeparam = this.$route.query.docType;
        if (filterdocTypeparam) {
          if (typeof filterdocTypeparam === 'string') this.selectedDocType = filterdocTypeparam;
          else this.selectedDocType = filterdocTypeparam.toString();
        } else {
          this.selectedDocType = 'All';
        }

        const filterdaysparam = this.$route.query.days;
        if (filterdaysparam) {
          this.daySelected = filterdaysparam;
        } else {
          this.daySelected = this.defaultDay;
        }

        const filtersessionTypesparam = this.$route.query.session_type;
        if (filtersessionTypesparam) {
          if (typeof filtersessionTypesparam === 'string') this.selectedDocTypesFacetsValues['types'].push(filtersessionTypesparam);
          else this.selectedDocTypesFacetsValues['types'] = Object.values(filtersessionTypesparam);
        }
        const filtersessionTracksparam = this.$route.query.tracks;
        if (filtersessionTracksparam) {
          if (typeof filtersessionTracksparam === 'string') this.selectedDocTypesFacetsValues['tracks'].push(filtersessionTracksparam);
          else this.selectedDocTypesFacetsValues['tracks'] = Object.values(filtersessionTracksparam);
        }
        const filtertopicsparam = this.$route.query.topics;
        if (filtertopicsparam) {
          if (typeof filtertopicsparam === 'string') this.selectedDocTypesFacetsValues['topics'].push(filtertopicsparam);
          else this.selectedDocTypesFacetsValues['topics'] = Object.values(filtertopicsparam);
        }
        const filtercountryparam = this.$route.query.country;
        if (filtercountryparam) {
          if (typeof filtercountryparam === 'string') this.selectedDocTypesFacetsValues['country'].push(filtercountryparam);
          else this.selectedDocTypesFacetsValues['country'] = Object.values(filtercountryparam);
        }
        const filterspecialitiesparam = this.$route.query.specialities;
        if (filterspecialitiesparam) {
          if (typeof filterspecialitiesparam === 'string') this.selectedDocTypesFacetsValues['specialities'].push(filterspecialitiesparam);
          else this.selectedDocTypesFacetsValues['specialities'] = Object.values(filterspecialitiesparam);
        }
        const filterroomsparam = this.$route.query.rooms;
        if (filterroomsparam) {
          if (typeof filterroomsparam === 'string') this.selectedDocTypesFacetsValues['rooms'].push(filterroomsparam);
          else this.selectedDocTypesFacetsValues['rooms'] = Object.values(filterroomsparam);
        }

        const vueparam = this.$route.query.vue;
        if (vueparam == 'list') {
          this.perPage = 999;
          this.sort = 'Earliest';
          this.showCards = false;
          this.showList = true;
          this.showCalendar = false;
        } else if (vueparam == 'calendar') {
          this.perPage = 999;
          this.sort = 'Earliest';
          this.showCards = false;
          this.showList = false;
          this.showCalendar = true;
        } else {
          this.perPage = 20;
          this.sort = 'MostRelevant';
          this.showCards = true;
          this.showList = false;
          this.showCalendar = false;
        }
      },
      updateSelectedDocType(docType) {
        this.selectedDocType = docType ? docType : this.selectedDocTypeDefault;
        this.selectedDocTypeIndex = this.docTypes.findIndex((x) => x.value == docType);
      },
      updateFacets() {
        //console.log('updateFacets starts');
        let countryList;
        if (this.facetContributors?.country) {
          countryList = this.facetContributors?.country;
        }
        if (this.topicListes) {
          this.selectedDocTypesFacetsOptions['days'] = [];
          this.selectedDocTypesFacetsOptions['types'] = [];
          this.selectedDocTypesFacetsOptions['tracks'] = [];
          this.selectedDocTypesFacetsOptions['topics'] = [];
          this.selectedDocTypesFacetsOptions['country'] = [];
          this.selectedDocTypesFacetsOptions['specialities'] = [];
          this.selectedDocTypesFacetsOptions['rooms'] = [];
          if (this.selectedDocType === 'IndustryStand') this.topicListes['topics'] = this.topicListes['topicss'];

          const facets = this.topicListes;
          for (let facetIndex in facets) {
            const facetName = facetIndex;

            const facetOptions = facets[facetIndex];
            switch (facetName) {
              case 'days':
                {
                  for (let facetOptionIndex in facetOptions) {
                    const facetOption = facetOptions[facetOptionIndex];
                    this.selectedDocTypesFacetsOptions[facetName].push({
                      id: facetOption.value,
                      label: facetOption.text,
                    });
                  }
                }
                break;
              case 'tracks':
                {
                  for (let facetOptionIndex in facetOptions) {
                    const facetOption = facetOptions[facetOptionIndex];
                    this.selectedDocTypesFacetsOptions[facetName].push({
                      id: facetOption.value,
                      label: facetOption.text,
                    });
                  }
                }
                break;
              case 'types':
                {
                  for (let facetOptionIndex in facetOptions) {
                    const facetOption = facetOptions[facetOptionIndex];
                    this.selectedDocTypesFacetsOptions[facetName].push({
                      id: facetOption.value,
                      label: facetOption.text,
                    });
                  }
                }
                break;
              case 'topics':
                {
                  for (let facetOptionIndex in facetOptions) {
                    const facetOption = facetOptions[facetOptionIndex];

                    const topicId = facetOption.id;

                    const topicToAdd = {
                      name: 'topicNode',
                      id: topicId,
                      label: facetOption.label,
                      children: facetOption.children,
                    };
                    this.selectedDocTypesFacetsOptions[facetName].push(topicToAdd);
                  }
                }
                break;
              case 'country':
                {
                  for (let facetOptionIndex in facetOptions) {
                    const facetOption = facetOptions[facetOptionIndex];
                    this.selectedDocTypesFacetsOptions['country'].push({
                      id: facetOption.value,
                      label: facetOption.text,
                    });
                  }
                }
                break;
              case 'specialities':
                {
                  for (let facetOptionIndex in facetOptions) {
                    const facetOption = facetOptions[facetOptionIndex];
                    this.selectedDocTypesFacetsOptions[facetName].push({
                      id: facetOption.value,
                      label: facetOption.text,
                    });
                  }
                }
                break;
              case 'rooms':
                {
                  for (let facetOptionIndex in facetOptions) {
                    const facetOption = facetOptions[facetOptionIndex];
                    this.selectedDocTypesFacetsOptions[facetName].push({
                      id: facetOption.label,
                      label: facetOption.labelZone,
                    });
                  }
                }
                break;
            }
          }
          if (countryList && this.selectedDocType == 'Speaker') {
            this.selectedDocTypesFacetsOptions['country'] = [];
            countryList = countryList.map((c) => ({
              id: c.value,
              label: c.text,
            }));
            for (let contries in countryList) {
              const facetOption = countryList[contries];
              this.selectedDocTypesFacetsOptions['country'].push(facetOption);
            }
          }
        }
        //console.log('updateFacets ends');
      },

      async refreshSessionDetails({ currentPage }) {
        this.isLoading = true;
        this.currentPage = currentPage;
        let allFilter = this.getFullFilter(currentPage);
        this.updateQueryString();
        MediaApiService.getAllSessionElasticDetails(allFilter).then(async ({ data }) => {
          this.selectedDocTypeResultsElastic = data.documentsWithHighlight;
          this.selectedDocTypeResultsContributors = data.documentsWithHighlight;
          this.resultToSend = this.selectedDocTypeResultsElastic;
          this.resultsFacets = data.facets;
          this.totalRows = data.total;

          if (this.selectedDocType === 'IndustryStand') {
            this.selectedDocTypeResultsElastic = data.sourceIndu;
            this.totalRows = this.selectedDocTypeResultsElastic.length;
          }
          data.facets.rooms = this.channelsOptions;

          this.topicListes = data.facets;
          this.facetContributors = data.facets;
          this.docTypeAllData(this.topicListes);
          await new Promise((resolve) => setTimeout(resolve, 250));
          this.updateFacets();

          //If no results search 1st doctype and date with results
          if (this.totalRows < 1) {
            //If now results for current documentType, re search on 1st document type with results
            if (this.docTypes[this.selectedDocTypeIndex].count < 1) {
              const firstDocTypeWithResults = this.docTypes.find((x) => x.count > 0);
              if (firstDocTypeWithResults) {
                this.updateSelectedDocType(firstDocTypeWithResults.value);
                await this.refreshSessionDetails({ currentPage: 1 });
                return;
              }
            }
            //If now results for current date, re search on 1st date with results
            else {
              const firstDateStringWithResults = data.facets.days.find((x) => x.text > 0);
              if (firstDateStringWithResults) {
                const firstDateWithResults = DateHelper.onSiteDateTime(firstDateStringWithResults.value);
                const year = firstDateWithResults.getFullYear();
                const month = String(firstDateWithResults.getMonth() + 1).padStart(2, '0');
                const day = String(firstDateWithResults.getDate()).padStart(2, '0');
                this.daySelected = `${year}-${month}-${day}`;
                await this.refreshSessionDetails({ currentPage: 1 });
                return;
              }
            }
          } else {
            this.updateSelectedDocType(this.selectedDocType);
          }

          this.$nextTick(() => {
            this.isLoading = false;
          });
        });
      },

      async getChannelsOptions() {
        MediaApiService.getChannelsOptions(this.eventId).then(({ data }) => {
          if (data) {
            this.channelsOptions = data;
          }
        });
      },

      docTypeAllData(topicListes) {
        //console.log('docTypeAllData starts');
        //abstract
        this.sessionListes = [];
        if (topicListes?.abstract?.length > 0) {
          this.sessionListes.push({ value: topicListes.abstract[0].value, count: topicListes.abstract[0].text });
        } else {
          this.sessionListes.push({ value: 'abstract', count: 0 });
        }
        //all sessions
        if (topicListes?.allSession?.length > 0) {
          this.sessionListes.push({ value: topicListes.allSession[0].value, count: topicListes.allSession[0].text });
        } else {
          this.sessionListes.push({ value: 'allSession', count: 0 });
        }
        //scientific session
        if (topicListes?.digitalSession?.length > 0) {
          this.sessionListes.push({ value: topicListes.digitalSession[0].value, count: topicListes.digitalSession[0].text });
        } else {
          this.sessionListes.push({ value: 'digitalSession', count: 0 });
        }
        //digitalSessionDailyHighLight
        if (topicListes?.digitalSessionDailyHighLight?.length > 0) {
          this.sessionListes.push({ value: topicListes.digitalSessionDailyHighLight[0].value, count: topicListes.digitalSessionDailyHighLight[0].text });
        } else {
          this.sessionListes.push({ value: 'digitalSessionDailyHighLight', count: 0 });
        }
        //DigitalSessionEscTV
        if (topicListes?.digitalSessionEscTV?.length > 0) {
          this.sessionListes.push({ value: topicListes.digitalSessionEscTV[0].value, count: topicListes.digitalSessionEscTV[0].text });
        } else {
          this.sessionListes.push({ value: 'digitalSessionEscTV', count: 0 });
        }
        //digitalSessionIndustry
        if (topicListes?.digitalSessionIndustry?.length > 0) {
          this.sessionListes.push({ value: topicListes.digitalSessionIndustry[0].value, count: topicListes.digitalSessionIndustry[0].text });
        } else {
          this.sessionListes.push({ value: 'digitalSessionIndustry', count: 0 });
        }

        //Exhibition Stands
        if (topicListes.industryStand) {
          this.sessionListes.push({ value: topicListes.industryStand[0].value, count: topicListes.industryStand[0].text });
        } else {
          this.sessionListes.push({ value: 'industryStand', count: 0 });
        }

        //  //Exhibition Stands
        // if (contributorsData && contributorsData?.speaker && contributorsData?.speaker?.length > 0) {
        //     this.sessionListes.push({ value: contributorsData.speaker[0].value, count: contributorsData.speaker[0].text });
        // } else {
        //     this.sessionListes.push({ value: 'Speaker', count: 0 });
        // }

        if (topicListes?.speaker?.length > 0) {
          this.sessionListes.push({ value: topicListes.speaker[0].value, count: topicListes.speaker[0].text });
        } else {
          this.sessionListes.push({ value: 'Speaker', count: 0 });
        }

        //facetContributors
        this.addDataToSessionList(this.sessionListes, this.selectedDocType);
        //console.log('docTypeAllData ends');
      },
      addDataToSessionList(docTypesRes) {
        //console.log('addDataToSessionList starts');
        if (docTypesRes) {
          let _docTypes = docTypesRes;
          if (_docTypes.length > 0) {
            switch (this.searchDocType) {
              case 'DigitalSession': {
                const hasAnySessions = _docTypes.find((x) => x.value == 'All');
                if (!hasAnySessions) _docTypes.push({ value: 'All', count: 0 });

                const hasAnyScientificSessions = _docTypes.find((x) => x.value == 'DigitalSession');
                if (!hasAnyScientificSessions) _docTypes.push({ value: 'DigitalSession', count: 0 });

                const hasAnyIndustrySessions = _docTypes.find((x) => x.value == 'DigitalSessionIndustry');
                if (!hasAnyIndustrySessions) _docTypes.push({ value: 'DigitalSessionIndustry', count: 0 });

                const hasAnyDailyHighlightSessions = _docTypes.find((x) => x.value == 'DigitalSessionDailyHighLight');
                if (!hasAnyDailyHighlightSessions) _docTypes.push({ value: 'DigitalSessionDailyHighLight', count: 0 });

                const hasAnyEscTvSessions = _docTypes.find((x) => x.value == 'DigitalSessionEscTV');
                if (!hasAnyEscTvSessions) _docTypes.push({ value: 'DigitalSessionEscTV', count: 0 });
                const hasAnyIndustryStands = _docTypes.find((x) => x.value == 'IndustryStand');
                if (!hasAnyIndustryStands) _docTypes.push({ value: 'IndustryStand', count: 0 });

                _docTypes = _docTypes.filter(
                  (x) =>
                    x.value === 'All' ||
                    (this.industryStandsSearch && x.value === 'IndustryStand') ||
                    (this.indexIndustries && x.value === 'DigitalSessionIndustry') ||
                    ((this.indexLiveSessions || this.indexOnDemandSessions) && x.value === 'DigitalSession') ||
                    ((this.indexLiveSessions || this.indexOnDemandSessions) &&
                      (this.eventId == constants.ESC_2024_EVENT_ID || this.eventId == constants.ESC_2023_EVENT_ID) &&
                      x.value === 'DigitalSessionEscTV') ||
                    ((this.indexLiveSessions || this.indexOnDemandSessions) &&
                      this.eventId == constants.ESC_2022_EVENT_ID &&
                      x.value === 'DigitalSessionDailyHighLight') ||
                    ((this.indexLiveSessions || this.indexOnDemandSessions) &&
                      this.eventId == constants.ESC_2024_EVENT_ID &&
                      x.value === 'DigitalSessionDailyHighLight')
                );

                break;
              }

              case 'Speaker': {
                const hasAnySpeakers = _docTypes.find((x) => x.value == 'Speaker');
                if (!hasAnySpeakers) _docTypes.push({ value: 'Speaker', count: 0 });

                _docTypes = _docTypes.filter((x) => this.indexSpeakers && x.value === 'Speaker');
                break;
              }

              case 'Abstract': {
                const hasAnyAbstracts = _docTypes.find((x) => x.value == 'Abstract');
                if (!hasAnyAbstracts) _docTypes.push({ value: 'Abstract', count: 0 });

                _docTypes = _docTypes.filter((x) => this.indexAbstracts && x.value === 'Abstract');
                break;
              }
            }
          }

          const facetsOrder = [
            { name: 'All', order: 1 },
            { name: 'DigitalSession', order: 5 },
            { name: 'DigitalSessionEscTV', order: 15 },
            { name: 'DigitalSessionDailyHighLight', order: 20 },
            { name: 'DigitalSessionIndustry', order: 30 },
            { name: 'Speaker', order: 40 },
            { name: 'Abstract', order: 50 },
            { name: 'IndustryStand', order: 60 },
          ];
          // Ordering facets...
          this.docTypes = _docTypes.sort((a, b) => facetsOrder.find((x) => x.name == a.value)?.order - facetsOrder.find((x) => x.name == b.value)?.order);

          /*
          this.selectedDocTypeDefault = desiredDocTypeName ? desiredDocTypeName : this.selectedDocTypeDefault;
          const firstDocTypeWithResults = this.docTypes.find((x) => x.count > 0);
          const desiredDocType = this.docTypes.find((x) => x.value == this.selectedDocTypeDefault);
          if (desiredDocType && desiredDocType.count > 0) {
            this.updateSelectedDocType(desiredDocType.value);
          } else {
            if (firstDocTypeWithResults) {
              let urlQuery = this.$route.query.text;
              if (urlQuery) {
                this.updateSelectedDocType(firstDocTypeWithResults.value);
              } else {
                this.updateSelectedDocType('DigitalSession');
              }
            }
          }
          // this.injectReplaysDocType(facets, results);
          */
        } else {
          this.docTypes = [];
        }
        //console.log('addDataToSessionList ends');
      },

      getFullFilter(currentPage) {
        switch (this.searchDocType) {
          case 'DigitalSession':
            this.endPointToUse = constants.ESC365_API_SCIENTIFIC_CONTENT_SEARCH_PROGRAMME;
            break;
          case 'Speaker':
            this.endPointToUse = constants.ESC365_API_SCIENTIFIC_CONTENT_SEARCH_FACULTY;
            break;
          case 'Abstract':
            this.endPointToUse = constants.ESC365_API_SCIENTIFIC_CONTENT_SEARCH_ABSTRACT;
            break;
        }

        let searchScientificContentsRequest = this.endPointToUse
          .replace('{fulltextquery}', encodeURIComponent(this.query))
          .replace('{page}', currentPage)
          .replace('{sort}', this.sort)
          .replace('{eventId}', this.escEventId)
          .replace('{indusEventId}', this.eventId)
          .replace('{docType}', this.selectedDocType);

        if (this.selectedDocTypesFacetsValues['topics'] && this.selectedDocTypesFacetsValues['topics'].length > 0) {
          searchScientificContentsRequest = searchScientificContentsRequest.concat(
            `&topics=${this.selectedDocTypesFacetsValues['topics'].map((x) => encodeURIComponent(x)).join('&topics=')}`
          );
        }

        if (this.selectedDocTypesFacetsValues['specialities'] && this.selectedDocTypesFacetsValues['specialities'].length > 0) {
          searchScientificContentsRequest = searchScientificContentsRequest.concat(
            `&specialities=${this.selectedDocTypesFacetsValues['specialities'].map((x) => encodeURIComponent(x)).join('&specialities=')}`
          );
        }

        if (this.daySelected) {
          searchScientificContentsRequest = searchScientificContentsRequest.concat(`&days=${encodeURIComponent(this.daySelected)}`);
        }
        if (this.selectedDocTypesFacetsValues['country'] && this.selectedDocTypesFacetsValues['country'].length > 0) {
          searchScientificContentsRequest = searchScientificContentsRequest.concat(
            `&country=${this.selectedDocTypesFacetsValues['country'].map((x) => encodeURIComponent(x)).join('&country=')}`
          );
        }
        if (this.selectedDocTypesFacetsValues['types'] && this.selectedDocTypesFacetsValues['types'].length > 0) {
          searchScientificContentsRequest = searchScientificContentsRequest.concat(
            `&types=${this.selectedDocTypesFacetsValues['types'].map((x) => encodeURIComponent(x)).join('&types=')}`
          );
        }
        if (this.selectedDocTypesFacetsValues['tracks'] && this.selectedDocTypesFacetsValues['tracks'].length > 0) {
          searchScientificContentsRequest = searchScientificContentsRequest.concat(
            `&tracks=${this.selectedDocTypesFacetsValues['tracks'].map((x) => encodeURIComponent(x)).join('&tracks=')}`
          );
        }
        if (this.selectedDocTypesFacetsValues['rooms'] && this.selectedDocTypesFacetsValues['rooms'].length > 0) {
          searchScientificContentsRequest = searchScientificContentsRequest.concat(
            `&rooms=${this.selectedDocTypesFacetsValues['rooms'].map((x) => encodeURIComponent(x)).join('&rooms=')}`
          );
        }
        searchScientificContentsRequest = searchScientificContentsRequest.concat(`&pagesize=${this.perPage}`);

        return searchScientificContentsRequest;
      },
      getFacetConfiguration(value) {
        switch (value) {
          case 'days':
            return {
              hasControl: true,
              sm: 3,
              multiple: true,
              placeholder: 'Days',
              limit: 0,
              limitText: (count) => `Days (${count})`,
              display: false,
            };
          case 'rooms':
            return {
              hasControl: true,
              sm: 3,
              multiple: true,
              placeholder: 'Rooms',
              limit: 0,
              limitText: (count) => `Rooms (${count})`,
              display: true,
            };
          case 'types':
            return {
              hasControl: true,
              sm: 3,
              multiple: true,
              placeholder: 'Types',
              limit: 0,
              limitText: (count) => `Types (${count})`,
              display: true,
            };
          case 'tracks':
            return {
              hasControl: true,
              sm: 3,
              multiple: true,
              placeholder: 'Tracks',
              limit: 0,
              limitText: (count) => `Tracks (${count})`,
              display: true,
            };
          case 'topics':
            return {
              hasControl: true,
              sm: 5,
              multiple: true,
              placeholder: 'Topics',
              limit: 0,
              limitText: (count) => `Topics (${count})`,
              display: true,
            };
          case 'country':
            return {
              hasControl: true,
              multiple: true,
              placeholder: 'Countries/Regions',
              limit: 0,
              limitText: (count) => `Countries/Regions (${count})`,
              display: true,
            };
          case 'specialities':
            return {
              hasControl: true,
              multiple: true,
              placeholder: 'Products',
              limit: 0,
              limitText: (count) => `Product (${count})`,
              display: true,
            };
        }
      },
      getDocTypeFacets(value, forControls = false) {
        const startTimeRanges = [];
        for (const date of this.eventDates) {
          const startDate = DateTime.fromJSDate(date).set({ hour: 0, minute: 0, seconds: 0 });
          const endDate = startDate.set({ hour: 23, minute: 59, seconds: 59 });
          startTimeRanges.push({
            from: startDate.toISO(),
            to: endDate.toISO(),
            name: startDate.setLocale('en-gb').toLocaleString({ month: 'long', day: 'numeric' }),
          });
        }

        let docTypeFacets = {};

        switch (value) {
          case 'DigitalSession':
            docTypeFacets = {
              types: [{ type: 'value', name: 'types', size: 250, sort: { value: 'asc' } }],
              tracks: [{ type: 'value', name: 'tracks', size: 250, sort: { value: 'asc' } }],
              topics: [{ type: 'value', name: 'topics', size: 250, sort: { value: 'asc' } }], //,sort: { "value": "asc" }
              rooms: [{ type: 'value', name: 'rooms', size: 250, sort: { value: 'asc' } }],
            };

            if (!forControls) docTypeFacets.tracks = [{ type: 'value', name: 'tracks', size: 250, sort: { value: 'asc' } }];
            break;

          case 'DigitalSessionIndustry':
            docTypeFacets = {
              types: [{ type: 'value', name: 'types', size: 250, sort: { value: 'asc' } }],
              tracks: [{ type: 'value', name: 'tracks', size: 250, sort: { value: 'asc' } }],
              topics: [{ type: 'value', name: 'topics', size: 250, sort: { value: 'asc' } }],
              rooms: [{ type: 'value', name: 'rooms', size: 250, sort: { value: 'asc' } }],
            };
            break;

          case 'DigitalSessionDailyHighLight':
            docTypeFacets = {};
            break;

          case 'DigitalSessionEscTV':
            docTypeFacets = {};
            break;

          case 'Speaker':
            docTypeFacets = {
              country: [{ type: 'value', name: 'country', size: 250, sort: { value: 'asc' } }],
            };
            break;

          case 'Abstract':
            docTypeFacets = {
              topics: [{ type: 'value', name: 'topics', size: 250, sort: { value: 'asc' } }],
              country: [{ type: 'value', name: 'country', size: 250, sort: { value: 'asc' } }],
            };
            break;

          case 'IndustryStand':
            //docTypeFacets = {};
            docTypeFacets = {
              //session_type: [{ type: 'value', name: 'session_type', size: 250, sort: { value: 'asc' } }],
              //tracks: [{ type: 'value', name: 'tracks', size: 250, sort: { value: 'asc' } }],
              topics: [{ type: 'value', name: 'topics', size: 250, sort: { value: 'asc' } }],
              //session_type: [{ type: 'value', name: 'session_type', size: 250, sort: { value: 'asc' } }],
              specialities: [{ type: 'value', name: 'specialities', size: 250, sort: { value: 'asc' } }],
              rooms: [{ type: 'value', name: 'rooms', size: 250, sort: { value: 'asc' } }],
            };
            //if (!forControls) docTypeFacets.tracks = [{ type: 'value', name: 'tracks', size: 250, sort: { value: 'asc' } }];
            break;
        }
        return docTypeFacets;
      },
      getDocTypeHeader(value) {
        switch (value) {
          case 'All':
            return 'All Sessions';
          case 'DigitalSession':
            return 'Scientific Sessions';
          case 'DigitalSessionIndustry':
            return 'Industry Sessions';
          case 'DigitalSessionDailyHighLight':
            return 'Global Community';
          case 'DigitalSessionEscTV':
            return 'ESC TV';
          case 'Speaker':
            return 'Faculty & Presenters';
          case 'Abstract':
            return 'Abstracts';
          case 'IndustryStand':
            return 'Exhibition Stands';
          default:
            return value;
        }
      },
      addTopicWithParent(topicParentId, topicToAdd, topicsArr) {
        const topicParent = topicsArr.find((x) => x.id == topicParentId);
        if (topicParent) {
          if (!topicParent.children) topicParent.children = [];

          topicParent.children.push(topicToAdd);
        } else {
          for (let i = 0; i < topicsArr.length; i++) {
            const topic = topicsArr[i];
            if (topic.children) this.addTopicWithParent(topicParentId, topicToAdd, topic.children);
          }
        }
      },

      onTextInputKeydown(e) {
        if (e.which === 13) {
          this.onSearchClick();
          return;
        }
      },

      async onOnShowChange(args) {
        this.showCards = false;
        this.showList = false;
        this.showCalendar = false;
        if (args.showList || args.showCalendar) {
          this.perPage = 999;
          this.sort = 'Earliest';
        } else {
          this.perPage = 20;
          this.sort = 'MostRelevant';
        }
        this.showCards = args.showCards;
        this.showList = args.showList;
        this.showCalendar = args.showCalendar;
        //console.log('refreshSessionDetails onOnShowChange');
        await this.refreshSessionDetails({ currentPage: 1 });
      },

      async setDay(selectDay) {
        const year = selectDay.getFullYear();
        const month = String(selectDay.getMonth() + 1).padStart(2, '0');
        const day = String(selectDay.getDate()).padStart(2, '0');
        this.daySelected = `${year}-${month}-${day}`;
        //console.log('refreshSessionDetails selectDay');
        await this.refreshSessionDetails({ currentPage: 1 });
      },

      onActivateTab(newTabIndex, _, bvEvent) {
        if (this.docTypes[newTabIndex].count == 0) bvEvent.preventDefault();
      },
      OnChildLoading(isLoading) {
        //console.log('OnChildLoading', isLoading);
        this.isChildLoading = isLoading;
      },
      handleScroll() {
        this.showScrollToTop = window.scrollY > 50;
      },
      scrollToSearchBar() {
        this.$refs.searchBar?.scrollIntoViewExtended({ behavior: 'smooth', block: 'start',baseOffset:20,navOffset:true,daysBarOffset:false });
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  #tabsContainer {
    min-height: 78px;
  }

  .tab__title {
    vertical-align: text-bottom;
  }

  .tab__title_xl {
  }

  .tab__title__count {
    font-size: 0.75em;
  }

  .tab__title__active,
  .nav-link.active .tab__title {
    color: var(--congress);
    border-bottom: 2px solid var(--congress);
  }

  .btn-clear {
    font-size: 0.8em;
    padding: 0;
    background: #525252;
    border-color: transparent;
    border: 0;
    color: $secondary;

    &:hover,
    :focus,
    :active,
    :target {
      background: #525252;
      color: $secondary;
      border-color: transparent;
      text-decoration: underline;
      box-shadow: none;
    }
  }

  .clear-icon {
    &:hover {
      cursor: pointer;
    }
  }

  ::v-deep .spinner-border {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 3rem;
    height: 3rem;
  }

  /*ONBOARDING TOUR*/
  .row.v-tour__target--highlighted {
    border-radius: 0.75rem;
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.4);
    margin: 0;
    background: white;
  }

  .v-tour__target--highlighted,
  .v-step {
    z-index: inherit;
  }

  .search {
    font-size: 0.9em;
    background-color: #525252;

    & .btn {
      font-size: 0.9rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .search {
      padding: 8px;
      text-align: center;
      border-radius: 55px;
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-down(md) {
    .search {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 1rem;
    }

    .btn-clear {
      display: block;
      margin: auto;
    }
  }

  @include media-breakpoint-down(lg) {
    .filter__list {
      display: none;
    }
  }

  .jumbotron {
    padding: 0;
    background-color: transparent !important;
    margin: 0;
  }

  .display-3 {
    font-size: 1.8em;
  }

  .lead {
    font-size: 1.15em;
  }

  .searchInput {
    border-radius: 55px;
    border: 0;
    height: 40px;
  }

  .nav-tabs .nav-link {
    &:hover {
      border-color: #e9ecef #e9ecef #dee2e6;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .badge__selected {
    line-height: 1.38;
  }

  .badge-secondary {
    background-color: var(--congress-light);
    color: var(--congress-dark);
    font-size: 0.8em;
  }

  .btn-floating.scroller {
    position: fixed;
    border: 2px solid;
    bottom: 50px;
    right: 20px;
    z-index: 1000;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    margin: auto;
    padding: 5px 0px;
  }
</style>
