//import StoryblokClient from 'storyblok-js-client/dist/es5/index.es'

import { getStoryBlokClient } from 'src/services/storyblok';
import Vue from 'vue';

Vue.filter('formatUnixEpocTimePartForLog', (value) => {
    if (value)
        return Intl.DateTimeFormat('en-GB',
            {
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false,
                hourCycle: 'h24',
                timeZone: 'Europe/Paris'
            }
        ).format(new Date(value));
});

Vue.filter('formatUnixEpocTimePart', (value) => {
    if (value)
        return Intl.DateTimeFormat('en-GB',
            {
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                hourCycle: 'h24',
                timeZone: 'Europe/Paris'
            }
        ).format(new Date(value));
});

Vue.filter('formatUnixEpocTimePart_TZ', (value) => {
    if (value) {
        return Intl.DateTimeFormat('en-GB',
            {
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                hourCycle: 'h24',
                timeZoneName: 'short'
            }
        ).formatToParts(new Date(value)).map(({ type, value }) => {
            switch (type) {
                case 'timeZoneName': return `(${value})`;
                default: return value;
            }
        }).reduce((string, part) => string + part);
        //return Intl.DateTimeFormat('en-GB', { hour: 'numeric', minute: 'numeric',hour12:false, hourCycle:'h24',timeZoneName:'short'}).format(new Date(value));
    }
});

Vue.filter('formatUnixEpocDayPart', (value) => {
    if (value)
        return Intl.DateTimeFormat('en-GB',
            {
                month: 'long',
                day: 'numeric',
                timeZone: 'Europe/Paris'
            }
        ).format(new Date(value));
});

Vue.filter('formatIsoDayPart', (value) => {
    if (value)
        return value.split('T')[0];
});
Vue.filter('formatIsoTimePart', (value) => {
    if (value)
        return value.split('T')[1].split('.')[0];
});

Vue.filter('trim', (value) => {
    if (value)
        return value.trim();
});

Vue.filter('formatStoryRichText', (value) => {
    if (value)
        return getStoryBlokClient().richTextResolver.render(value)
})

Vue.filter('formatUnixEpocDayPartWithYear', (value) => {
    if (value)
        return Intl.DateTimeFormat('en-GB',
            {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'Europe/Paris'
            }
        ).format(new Date(value));
});

Vue.filter('formatUnixEpocDayPartOnly', (value) => {
    if (value)
        return Intl.DateTimeFormat('en-GB',
            {
                day: 'numeric',
                timeZone: 'Europe/Paris'
            }
        ).format(new Date(value));
});

Vue.filter('firstSentence', (value) => {
    if (value)
        return value.split('.')[0].trim();
});


Vue.filter('formatChannelName', (value) => {
    let formatedChannelName = value;
    /*
    if (value) {
        const channeltokenized = value.split('-');
        if (channeltokenized) {
            if (channeltokenized.length > 0)
                formatedChannelName = `<div class="channels-row__name">${channeltokenized[0].trim()}</div>`;
            if (channeltokenized.length > 1)
                formatedChannelName += `<div class="channels-row__topic">${channeltokenized.slice(1).join('-')}</div>`;
        }
    }
    */
    return formatedChannelName;
});

Vue.filter('formatChannelNameWithoutTopic', (value) => {
    let formatedChannelName = value;
    /*
    if (value) {
        const channeltokenized = value.split('-');
        if (channeltokenized) {
            if (channeltokenized.length > 0)
                formatedChannelName = `${channeltokenized[0].trim()}`;
        }
    }
    */
    return formatedChannelName;
});
